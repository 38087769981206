import { isProduction } from '@exchange/helpers/environment';
import { identifiersService } from '@exchange/libs/utils/client-identifiers/src';

const log =
  (type: string) =>
  (message?: unknown, ...vars: Array<unknown>) => {
    // eslint-disable-next-line no-console
    console[type](`${identifiersService.sessionId} ${message}`, ...vars);
  };

export const logger = {
  error: log('error'),
  info: log('info'),
  log: log('log'),
  warn: log('warn'),
  debug: log('debug'),
  trace: log('trace'),
};

export const nonProdConsoleInfo = (...args) => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.info(identifiersService.sessionId, ...args, new Date().toString());
  }
};
