const useHandlingLogTimeout = () => {
  let timer;

  const startTimer = (diffTargetAndServerTime: number, updateValueFunc: () => void) => {
    clearTimeout(timer);

    /** Be aware https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value */
    // eslint-disable-next-line no-bitwise
    const maxSigned32BitInteger = -(1 << 31) - 1;
    const delay = Math.min(Math.abs(diffTargetAndServerTime), maxSigned32BitInteger);

    timer = setTimeout(() => {
      if (delay !== diffTargetAndServerTime) {
        const diff = Math.abs(diffTargetAndServerTime) - delay;
        const newDiffTargetAndServerTime = diffTargetAndServerTime > 0 ? diff : diff * -1;

        return startTimer(newDiffTargetAndServerTime, updateValueFunc);
      }

      return updateValueFunc();
    }, delay);
  };

  return {
    startTimer,
    clearTimer: () => clearTimeout(timer),
  };
};

export default useHandlingLogTimeout;
