interface RetryInfo {
  /** Time to delay before attempting to reconnect. */
  reconnectInterval: number;
  /** The maximum time to delay a reconnection attempt. */
  maxReconnectInterval: number;
  /** The rate of increase of the reconnect delay. Is used to back off reconnect attempts when connection issue persists. */
  reconnectDecay: number;
  /** The number of attempted reconnects since starting, or the last successful connection. */
  reconnectAttempts: number;
  /** callback functions waiting for the next retry attempt */
  listeners: Array<() => void>;
  isTickerRunning: boolean;
}

class RetryService {
  private retryInfo: RetryInfo = {
    reconnectInterval: 1_500,
    maxReconnectInterval: 20_000,
    reconnectDecay: 1.5,
    reconnectAttempts: 0,
    listeners: [],
    isTickerRunning: false,
  };

  private setTickerRunning = (running: boolean) => {
    this.retryInfo.isTickerRunning = running;

    if (!running) {
      this.retryInfo.reconnectAttempts = 0;
    }
  };

  private runRetryTicker = () => {
    const { reconnectInterval, reconnectDecay, reconnectAttempts, maxReconnectInterval, listeners } = this.retryInfo;
    const reconnectTimeout = reconnectInterval * reconnectDecay ** reconnectAttempts;

    this.setTickerRunning(true);

    setTimeout(
      () => {
        if (listeners.length) {
          this.retryInfo.reconnectAttempts = reconnectAttempts + 1;

          while (listeners.length) {
            listeners.pop()?.();
          }

          this.runRetryTicker();
        } else {
          this.setTickerRunning(false);
        }
      },
      Math.min(maxReconnectInterval, reconnectTimeout),
    );
  };

  public waitForNextRetryTick = () => {
    const { isTickerRunning } = this.retryInfo;

    if (!isTickerRunning) {
      this.runRetryTicker();
    }

    return new Promise<void>((resolve) => {
      this.retryInfo.listeners.push(resolve);
    });
  };
}

const rs = new RetryService();

export default rs;
