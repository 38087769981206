import { differenceInMilliseconds, subMilliseconds } from 'date-fns';
import { defineAsyncComponent, reactive, shallowRef } from 'vue';

import { modalService } from '@exchange/libs/modals/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

const ErrorModal = defineAsyncComponent(() => import('@exchange/libs/modals/src/lib/common/ErrorModal.vue'));

interface ClientToServerTime {
  diff?: number /** diff of milliseconds since the Unix Epoch */;
  fetchingPromise?: Promise<number>;
}

export class ServerClientTimeService {
  private readonly logPrefix = 'ServerClientTime:';

  private clientToServerTime = reactive<ClientToServerTime>({
    diff: undefined,
    fetchingPromise: undefined,
  });

  public resetClientToServerTime = () => {
    this.clientToServerTime.diff = undefined;
  };

  private getClientServerTimeDiff = async () => {
    const { epoch_millis: serverTime } = await PublicRest.Time.get();

    return differenceInMilliseconds(Date.now(), serverTime);
  };

  public establishClientServerTimeDiff = async (): Promise<number> => {
    try {
      if (this.clientToServerTime.diff !== undefined) {
        return this.clientToServerTime.diff;
      }

      if (this.clientToServerTime.fetchingPromise) {
        return await this.clientToServerTime.fetchingPromise;
      }

      this.clientToServerTime.fetchingPromise = this.getClientServerTimeDiff();
      this.clientToServerTime.diff = await this.clientToServerTime.fetchingPromise;
      this.clientToServerTime.fetchingPromise = undefined;

      return this.clientToServerTime.diff;
    } catch (error) {
      this.clientToServerTime.fetchingPromise = undefined;
      logger.error(`${this.logPrefix}Getting server time failed; retrying later`, error);
      await retryService.waitForNextRetryTick();
      return this.establishClientServerTimeDiff();
    }
  };

  public isAbnormalTime = (msDelta: number) => Math.abs(msDelta) > CONSTANTS.CLOCK_SKEW_MS;

  public isInFutureTime = (msTime: number, msNow: number) => differenceInMilliseconds(msTime, msNow) > CONSTANTS.CLOCK_SKEW_MS;

  public isInPastTime = (msTime: number, msNow: number) => differenceInMilliseconds(msNow, msTime) > CONSTANTS.CLOCK_SKEW_MS;

  public checkClientAbnormalTime = async () => {
    const delta = await this.establishClientServerTimeDiff();

    return this.isAbnormalTime(delta);
  };

  public getReputedlyServerTime = async () => {
    const diff = await this.establishClientServerTimeDiff();

    return subMilliseconds(Date.now(), diff).getTime();
  };

  public showWrongClientTimeModal = () => {
    modalService.show(
      shallowRef(ErrorModal),
      {
        messageKey: 'modules.errors.loginFailedClaimIssuedInFuture',
        closeButtonKey: 'fundamentals.understood',
      },
      {
        'x-error-close': () => {
          modalService.clear();
        },
      },
    );
  };

  public checkJWTClaimAbnormalTime = ({ msExp, msIat }: { msExp: number; msIat: number }) => {
    const now = Date.now();
    const isExpired = this.isInPastTime(msExp, now);
    const iatInFuture = this.isInFutureTime(msIat, now);

    return isExpired || iatInFuture;
  };
}

const st = new ServerClientTimeService();

export default st;
