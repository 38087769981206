import { ref, onUnmounted } from 'vue';

import { PromoConfigFlag } from '@exchange/libs/utils/launchdarkly/src';
import { serverClientTimeService } from '@exchange/libs/utils/server-client-time/src';

import useHandlingLogTimeout from './useHandlingLogTimeout';

const usePromoTiming = (getPromoConfig: () => Promise<PromoConfigFlag>) => {
  const promoIsActive = ref(false);

  const updatePromo = (value: boolean) => {
    promoIsActive.value = value;
  };

  const getServerTime = serverClientTimeService.getReputedlyServerTime;

  const diffToPromoTime = async () => {
    try {
      const promoConfig = await getPromoConfig();
      const nowOnServer = await getServerTime();

      const promoStartTime = new Date(promoConfig.start).getTime();
      const promoEndTime = new Date(promoConfig.end).getTime();

      return {
        startDiff: nowOnServer - promoStartTime,
        endDiff: promoEndTime - nowOnServer,
      };
    } catch (e) {
      return diffToPromoTime();
    }
  };

  const { clearTimer: clearTimerStart, startTimer: startTimerStart } = useHandlingLogTimeout();
  const { clearTimer: clearTimerEnd, startTimer: startTimerEnd } = useHandlingLogTimeout();

  diffToPromoTime().then(({ startDiff, endDiff }) => {
    if (startDiff >= 0) {
      /** started */
      if (endDiff > 0) {
        /** not finished */
        updatePromo(true);
        startTimerEnd(endDiff, () => updatePromo(false));
      } else {
        /** and finished */
        updatePromo(false);
      }
    } else {
      /** not started */
      startTimerStart(startDiff, () => {
        updatePromo(true);

        startTimerEnd(endDiff, () => updatePromo(false));
      });
    }
  });

  onUnmounted(() => {
    clearTimerStart();
    clearTimerEnd();
  });

  return {
    promoIsActive,
  };
};

export default usePromoTiming;
