import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import isRetryAllowed from 'is-retry-allowed';

const namespace = 'axios-retry';
const IDEMPOTENT_HTTP_METHODS = ['get', 'head', 'options', 'delete'];

interface AxiosRetryConfig {
  retries: number; // Number of retries
  shouldResetTimeout: boolean; //  Defines if the timeout should be reset between retries
  retryCondition: (error: AxiosError) => boolean; // A function to determine if the error can be retried
  retryDelay: (retryCount: number, error: unknown) => number; // A function to determine the delay between retry requests
}

function isNetworkError(error: AxiosError): boolean {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error)
  ); // Prevents retrying unsafe errors
}

function isRetryableError(error: AxiosError): boolean {
  return error.code !== 'ECONNABORTED' && (!error.response || (error.response.status >= 500 && error.response.status <= 599));
}

function isIdempotentRequestError(error: AxiosError): boolean {
  if (!error.config) {
    return false;
  }

  const method = error.config.method ? error.config.method.toLowerCase() : '';

  return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(method) !== -1;
}

function isNetworkOrIdempotentRequestError(error: AxiosError): boolean {
  return isNetworkError(error) || isIdempotentRequestError(error);
}

function shortDelay() {
  return 200;
}

/**
 * Initializes and returns the retry state for the given request/config
 */
function getCurrentState(config: AxiosRequestConfig) {
  const currentState = config[namespace] || {};

  currentState.retryCount = currentState.retryCount || 0;
  // eslint-disable-next-line no-param-reassign
  config[namespace] = currentState;

  return currentState;
}

/**
 * Returns the axios-retry options for the current request
 */
function getRequestOptions(config: AxiosRequestConfig, defaultOptions?: AxiosRetryConfig): AxiosRetryConfig {
  return { ...defaultOptions, ...config[namespace] };
}

function fixConfig(axios: AxiosInstance, config: AxiosRequestConfig) {
  if (axios.defaults.httpAgent === config.httpAgent) {
    // eslint-disable-next-line no-param-reassign
    delete config.httpAgent;
  }

  if (axios.defaults.httpsAgent === config.httpsAgent) {
    // eslint-disable-next-line no-param-reassign
    delete config.httpsAgent;
  }
}

const axiosRetryInterceptor = (axios: AxiosInstance, defaultOptions?: AxiosRetryConfig) => {
  axios.interceptors.request.use((config) => {
    const currentState = getCurrentState(config);

    currentState.lastRequestTime = Date.now();

    return config;
  });

  axios.interceptors.response.use(undefined, (error) => {
    const { config } = error;

    if (!config) {
      // No information to retry the request
      return Promise.reject(error);
    }

    const { retries = 3, retryCondition = isNetworkOrIdempotentRequestError, retryDelay = shortDelay, shouldResetTimeout = false } = getRequestOptions(config, defaultOptions);

    const currentState = getCurrentState(config);

    const shouldRetry = retryCondition(error) && currentState.retryCount < retries;

    if (shouldRetry) {
      currentState.retryCount += 1;
      const delay = retryDelay(currentState.retryCount, error);

      // AxiosInstance fails merging this configuration to the default configuration because it has an issue
      // with circular structures: https://github.com/mzabriskie/axios/issues/370
      fixConfig(axios, config);

      if (!shouldResetTimeout && config.timeout && currentState.lastRequestTime) {
        const lastRequestDuration = Date.now() - currentState.lastRequestTime;

        // Minimum 1ms timeout (passing 0 or less to XHR means no timeout)
        config.timeout = Math.max(config.timeout - lastRequestDuration - delay, 1);
      }

      config.transformRequest = [(data) => data];

      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(() => resolve(axios(config)), delay));
    }

    return Promise.reject(error);
  });
};

export default axiosRetryInterceptor;
