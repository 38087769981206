import { authService } from '@exchange/libs/auth/service/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

export interface RestAuthHelpers {
  getToken: (params: { useSubaccount: boolean }) => Promise<string | undefined>;
  getRefreshedToken: (params: { useSubaccount: boolean; url?: string; attempt: number }) => Promise<string | undefined>;
  handleMultiple401Failure: () => Promise<void>;
}

const restAuthHelpers: RestAuthHelpers = {
  getToken: async ({ useSubaccount }) => {
    await authService.waitForAuthIsChecked({ useSubaccount });

    return authService.getToken(useSubaccount);
  },
  getRefreshedToken: async ({ useSubaccount, url, attempt }) => {
    logger.info(`Auth: getting refreshed token for ${url}, attempt ${attempt}`, new Date());
    await authService.triggerSilentAuthorization({ logError: true });

    return authService.getToken(useSubaccount);
  },
  handleMultiple401Failure: () => {
    logger.info('Auth: resetting after multiple failed (401) requests', new Date());
    return authService.resetAuth({ isUserInteraction: false, unsubscribeAccountHistory: false });
  },
};

export default restAuthHelpers;
