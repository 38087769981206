import FloatingVue from 'floating-vue';
import type { App } from 'vue';
import 'floating-vue/dist/style.css';

import isTouch from '@exchange/helpers/is-touch';

const config = {
  // Disable popper components
  disabled: false,
  // Default position offset along main axis (px)
  distance: 5,
  // Default position offset along cross axis (px)
  skidding: 15,
  // Default container where the tooltip will be appended
  container: '#app',
  // Element used to compute position and size boundaries
  boundary: undefined,
  // Skip delay & CSS transitions when another popper is shown, so that the popper appear to instantly move to the new position.
  instantMove: false,
  // Auto destroy tooltip DOM nodes (ms)
  disposeTimeout: 5000,
  // Triggers on the popper itself
  popperTriggers: [],
  // Positioning strategy
  strategy: 'absolute',
  // Prevent overflow
  preventOverflow: false,
  // Flip to the opposite placement if needed
  flip: false,
  // Shift on the cross axis to prevent the popper from overflowing
  shift: true,
  // Overflow padding (px)
  overflowPadding: 0,
  // Arrow padding (px)
  arrowPadding: 0,
  // Compute arrow overflow (useful to hide it)
  arrowOverflow: true,
  // Themes
  themes: {
    tooltip: {
      // Default tooltip placement relative to target element
      placement: 'top',
      // Default events that trigger the tooltip
      triggers: ['hover', 'focus', 'touch'],
      // Delay (ms)
      delay: {
        show: 200,
        hide: 0,
      },
      // Update popper on content resize
      handleResize: false,
      // Enable HTML content in directive
      html: false,
      // Displayed when tooltip content is loading
      loadingContent: '...',
      // Hide on click outside
      autoHide: true,
    },
    dropdown: {
      // Default dropdown placement relative to target element
      placement: 'bottom',
      // Default events that trigger the dropdown
      triggers: ['click'],
      // Delay (ms)
      delay: 0,
      // Update popper on content resize
      handleResize: true,
      // Hide on click outside
      autoHide: true,
    },
    menu: {
      $extend: 'dropdown',
      triggers: ['hover', 'focus'],
      popperTriggers: ['hover', 'focus'],
      delay: {
        show: 0,
        hide: 400,
      },
    },
  },
};

class TooltipService {
  private initialized = false;

  public init = (app: App, defaultContainer = '#app') => {
    if (this.initialized) {
      return;
    }

    const options = {
      ...config,
      container: defaultContainer,
      themes: {
        ...config.themes,
        'tooltip-light': {
          $extend: 'tooltip',
        },
        'tooltip-dark': {
          $extend: 'tooltip',
        },
        'tooltip-warning-wide': {
          $extend: 'tooltip',
        },
        'tooltip-fiat': {
          $extend: 'tooltip',
          autoHide: false,
        },
        'tooltip-fee-level': {
          $extend: 'tooltip',
        },
        'tooltip-order-filter': {
          $extend: 'tooltip',
        },
        'tooltip-instant-trade': {
          $extend: 'tooltip',
        },
        'tooltip-coin-network': {
          $extend: 'tooltip',
        },
        'tooltip-portfolio-value-in': {
          $extend: 'tooltip',
        },
      },
    };

    const triggers = isTouch() ? ['click'] : ['hover', 'focus', 'touch'];

    options.themes.tooltip.triggers = triggers;

    app.use(FloatingVue, options);

    this.initialized = true;
  };
}

const ts = new TooltipService();

export default ts;
