import { useDocumentVisibility } from '@vueuse/core';
import { throttle } from 'lodash/fp';
import { computed, reactive, watch } from 'vue';

import { requestEssentialData } from '@exchange/essential-fetches';
import { syncWithStorage } from '@exchange/helpers/persistance-helper';
import { authService } from '@exchange/libs/auth/service/src';
import { marketService } from '@exchange/libs/market/service/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { FastSpotWebSocketManager, BunSpotWebSocketManager } from '@exchange/libs/utils/wss/src';

interface SleepData {
  appIsAsleep: boolean;
  sleepScheduledAt: number | undefined;
  globalSleepTimeout: number | undefined;
  globalBackgroundSync: number;
}

class SleepService {
  private readonly logPrefix = 'SleepService:';

  private sleepData = reactive<SleepData>({
    appIsAsleep: false,
    sleepScheduledAt: undefined,
    globalSleepTimeout: undefined,
    globalBackgroundSync: 0,
  });

  public appIsAsleep = computed(() => this.sleepData.appIsAsleep);

  private visibility = useDocumentVisibility();

  constructor() {
    watch(this.visibility, (nVal, oVal) => {
      if (nVal !== oVal) {
        this.updateGlobalSleepDetection({ visible: nVal === 'visible' });
      }
    });
  }

  private webWssOnSleepStart = async () => {
    if (capIsNativePlatform()) {
      return;
    }

    await Promise.all([FastSpotWebSocketManager.disconnect('sleep'), BunSpotWebSocketManager.disconnect('sleep')]);
  };

  private webWssOnSleepStop = async () => {
    if (capIsNativePlatform()) {
      return;
    }

    await authService.checkAndTryToUpdateAuthUponReturningToFromBeingInactive(true);
    await Promise.all([FastSpotWebSocketManager.connect('sleep'), BunSpotWebSocketManager.connect('sleep')]);
  };

  private stopSleeping = () => {
    clearTimeout(this.sleepData.globalSleepTimeout);
    this.sleepData.globalSleepTimeout = undefined;

    const sleepStoppedAt = new Date().getTime();
    const shouldHaveBeenSleeping = sleepStoppedAt - (this.sleepData.sleepScheduledAt || sleepStoppedAt) >= CONSTANTS.SET_APP_ASLEEP_TIMEOUT;

    this.sleepData.sleepScheduledAt = undefined;

    if (this.sleepData.appIsAsleep || shouldHaveBeenSleeping) {
      logger.info(`${this.logPrefix} Sleep finished`, new Date());
      clearInterval(this.sleepData.globalBackgroundSync);

      requestEssentialData('sleep');
      this.sleepData.appIsAsleep = false;
      this.webWssOnSleepStop();
    }
  };

  private scheduleSleeping = () => {
    if (this.sleepData.appIsAsleep || this.sleepData.globalSleepTimeout !== undefined) {
      return;
    }

    this.sleepData.sleepScheduledAt = new Date().getTime();

    this.sleepData.globalSleepTimeout = window.setTimeout(() => {
      logger.info(`${this.logPrefix} Sleep initiated`, new Date());
      this.sleepData.appIsAsleep = true;
      this.startGlobalBackgroundSync();
      this.webWssOnSleepStart();
    }, CONSTANTS.SET_APP_ASLEEP_TIMEOUT);
  };

  public updateGlobalSleepDetection = ({ visible }: { visible: boolean }) => {
    if (visible) {
      this.stopSleeping();
    } else {
      this.scheduleSleeping();
    }
  };

  private startGlobalBackgroundSync = () => {
    clearInterval(this.sleepData.globalBackgroundSync);

    if (!this.sleepData.appIsAsleep) {
      return;
    }

    const throttleMarketTickerCall = throttle(10_000, async () => {
      const marketId = syncWithStorage(CONSTANTS.SELECTED_MARKET, CONSTANTS.DEFAULT_MARKET_ID);

      if (marketId) {
        try {
          const { last_price: lastPrice } = await marketService.getMarketTicker(marketId);

          marketService.updateLastPrice({ lastPrice, marketId });
        } catch (e) {
          logger.warn(`${this.logPrefix} Obtaining last market price failed:`, e);
        }
      }
    });

    this.sleepData.globalBackgroundSync = window.setInterval(throttleMarketTickerCall, 10000);
  };
}

const ss = new SleepService();

export default ss;
