import { settingsService } from '@exchange/libs/settings/service/src';
import { langsInfoService } from '@exchange/libs/utils/langs-info/src';

import restAuthHelpers from './auth-helpers';
import { restFactory } from './helpers';

export default restFactory(restAuthHelpers, {
  getLanguage: () => langsInfoService.language.value,
  getRateLimitWarningsSettings: () => settingsService.settings.userSettings.rateLimitWarnings,
});
