import axios, { Method, AxiosResponse } from 'axios';
import merge from 'lodash/merge';

import { identifiersService } from '@exchange/libs/utils/client-identifiers/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { RestAuthHelpers } from './auth-helpers';
import axiosRetryInterceptor from './axios-retry-interceptor';
import rateLimitInterceptor from './rate-limit-interceptor';
import packageJson from '../../../../../../package.json';

const STATUSES_IGNORE = /^(2|3)\d\d$|^40(1|3|4)$|^4(22|10)$|^50(3|4)$|^(0)$/;

const getAuthorizationHeader = (token?: string) => `Bearer ${token}`;

export function executeRequest(
  authorized: boolean,
  { url, method = 'get', payload, params, headers, base },
  requestOptions: { respondWithReaders: boolean; useSubaccount: boolean },
  getRefreshedToken: RestAuthHelpers['getRefreshedToken'] = () => Promise.resolve(undefined),
  attempt = 0,
) {
  const isFastApi = base.includes('/fast/');

  // https://github.com/axios/axios#request-config
  const config = {
    url,
    method: method as Method,
    baseURL: base,
    withCredentials: authorized && !isFastApi /** Credential is not supported if the CORS header 'Access-Control-Allow-Origin' is '*', which is the case with FAST */,
    headers,
    params,
    data: payload,
  };

  return axios.request(config).catch((e) => {
    if (e.response?.status === 401 && attempt < 3) {
      return getRefreshedToken?.({ useSubaccount: requestOptions.useSubaccount, url: e.response?.config?.url, attempt }).then((token) => {
        if (headers.authorization) {
          // eslint-disable-next-line no-param-reassign
          headers.authorization = getAuthorizationHeader(token);
        }

        return executeRequest(
          authorized,
          {
            url,
            method,
            payload,
            params,
            headers,
            base,
          },
          requestOptions,
          getRefreshedToken,
          attempt + 1,
        );
      });
    }

    throw e;
  });
}

const getDefaultHeaders = (lang: string) => ({
  'Accept-Language': lang,
  'Content-Type': 'application/json',
  [identifiersService.clientHeader]: `id=ui;device=${identifiersService.getDevice()};version=${packageJson.version}`,
  [identifiersService.sessionHeader]: identifiersService.sessionId,
});

const getErrorCode = (str) => (str ? str.replace(/\s+/g, '') : str);

const onError = (error, handleMultiple401Failure: () => void) => {
  const data: {
    url?: string;
    request?: string;
    requestPayload?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response?: string | any;
    bpRequestId?: string;
  } = {};
  let status;
  let throwError;

  if (error.response) {
    ({ status } = error.response);
    data.url = error.response.config?.url;
    data.requestPayload = error.response.config?.data;
    data.response = error.response.data;
    data.bpRequestId = error.response.headers?.['bp-request-id'];

    throwError = { ...error.response };
  } else {
    data.request = JSON.stringify(error.request);
    const errorCode = getErrorCode(error.message);

    throwError = {
      message: error.message,
      data: {
        error: errorCode, // Exchange API Error
        errors: [errorCode], // Broker API Errors
      },
    };
  }

  if (!STATUSES_IGNORE.test(status) && error.code !== 'ECONNABORTED' && Boolean(status)) {
    const capturedException = {
      error,
      extra: [['data', data]],
      tags: [
        ['status', status ? status.toString() : undefined],
        ['xhrUrl', data.url],
      ],
    };

    // see #1952
    if (error.status === 400 && data.response) {
      const error400 = data.response.error;

      capturedException.tags?.push(['400-error', error400]);
      logger.log('XHR error', capturedException);
    }
  }

  if (error.status === 401 || status === 401) {
    handleMultiple401Failure();
  }

  throw throwError;
};

export interface RequestOptions {
  respondWithReaders?: boolean;
  useSubaccount?: boolean;
}

export const request =
  (
    authorized: boolean,
    {
      getToken = () => Promise.resolve(undefined),
      getRefreshedToken = () => Promise.resolve(undefined),
      handleMultiple401Failure = () => Promise.resolve(undefined),
    }: {
      getToken?: RestAuthHelpers['getToken'];
      getRefreshedToken?: RestAuthHelpers['getRefreshedToken'];
      handleMultiple401Failure?: RestAuthHelpers['handleMultiple401Failure'];
    } = {},
    getLanguage: () => string = () => 'en',
  ) =>
  async (axiosRequestObj, options: RequestOptions = {}, headers = {}) => {
    const defaultOptions = {
      respondWithReaders: false,
      useSubaccount: true,
    };
    const requestOptions = merge(defaultOptions, options);
    const defaultHeaders = () => merge(getDefaultHeaders(getLanguage()), headers);
    const authorizedHeaders = (token: string) => merge({ authorization: getAuthorizationHeader(token) }, defaultHeaders());

    let accessToken: string | undefined;
    let requestObjHeaders = defaultHeaders();

    if (authorized) {
      accessToken = await getToken({ useSubaccount: requestOptions.useSubaccount });

      if (!accessToken) {
        throw new Error('No access token available for authenticated request!');
      }

      requestObjHeaders = authorizedHeaders(accessToken);
    }

    const requestObj = merge(axiosRequestObj, { headers: requestObjHeaders });

    return executeRequest(authorized, requestObj, requestOptions, getRefreshedToken)
      .then((result: AxiosResponse) => {
        if (requestOptions.respondWithReaders) {
          return {
            data: result.data,
            headers: result.headers,
          };
        }

        return result.data;
      })
      .catch((e) => onError(e, handleMultiple401Failure));
  };

export const restFactory = (
  authHelpers: RestAuthHelpers,
  {
    getLanguage,
    getRateLimitWarningsSettings,
  }: {
    getLanguage: () => string;
    getRateLimitWarningsSettings: () => { showCloseToHitting: boolean; showHit: boolean };
  },
) => {
  axiosRetryInterceptor(axios);
  rateLimitInterceptor(axios, getRateLimitWarningsSettings);

  return {
    request: request(true, authHelpers, getLanguage),
    unAuthorizedRequest: request(false, undefined, getLanguage),
  };
};
