import { ref } from 'vue';

import { getAppVersion } from '@exchange/helpers/environment';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { register } from './helpers';
import packageJson from '../../../../../../package.json';

class ServiceWorkerUpdateChecker {
  private readonly logPrefix = 'SW:';

  private readonly swFileName = 'service-worker.js';

  private readonly swEnvFileName = 'sw-env.json';

  public serviceWorkerUpdated = ref(false);

  public readonly appUpdateRequested = ref(false);

  private readonly registration = ref<ServiceWorkerRegistration | undefined>(undefined);

  private readonly swLogger = (logString: string, ...vars) => {
    logger.info(`${this.logPrefix} ${packageJson.version} - ${logString}`, ...vars);
  };

  private readonly reloadCurrentPage = (reason: string) => {
    this.swLogger(reason);
    window.location.reload();
  };

  private readonly subscribeOnStatechange = (waitingRegistration: ServiceWorker) => {
    waitingRegistration.addEventListener('statechange', (e) => {
      if ((e.target as ServiceWorker).state === 'activated') {
        this.reloadCurrentPage('Service worker is activated, reloading the page...');
      }
    });
  };

  private readonly sendSkipWaiting = (registration?: ServiceWorkerRegistration): boolean => {
    if (!registration || !registration.waiting) {
      return false;
    }

    registration.waiting.postMessage({
      type: 'SKIP_WAITING',
    });

    return true;
  };

  private fetchVersionFromEnvFile = (): Promise<string> =>
    fetch(`${process.env.BASE_URL}${this.swEnvFileName}`, { cache: 'no-store' })
      .then((response) => response.json())
      .then((content) => {
        const { version } = content;

        return version.toString();
      })
      .catch((e) => this.swLogger('Env fetch failed', e));

  private readonly onServiceWorkerUpdate = async (registration: ServiceWorkerRegistration) => {
    this.registration.value = registration;
    const currentVersion = getAppVersion();

    const fetchedVersion = await this.fetchVersionFromEnvFile();

    this.swLogger('Current version', currentVersion, 'Fetched version', fetchedVersion);

    if (currentVersion !== fetchedVersion) {
      this.serviceWorkerUpdated.value = true;
    }
  };

  private readonly doRegistration = () => {
    nonProdConsoleInfo(`${this.logPrefix} starting registration`);

    register(`${process.env.BASE_URL}${this.swFileName}`, {
      registrationOptions: {
        /** https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#parameters */
        scope: '/',
        // Starting in 68, the HTTP cache will be ignored when requesting updates to the service worker script
        // Requests for importScripts will still go via the HTTP cache.
        // But this is just the default—a new registration option
        // updateViaCache is available that offers control over this behavior
        // updateViaCache: 'none',
      },
      ready: (registration: ServiceWorkerRegistration) => {
        nonProdConsoleInfo(`${this.logPrefix} Service worker is active;`, registration);
      },
      registered: (registration: ServiceWorkerRegistration) => {
        this.swLogger('Service worker has been registered.');
        // Check every 1 hour if there is available update
        setInterval(() => {
          registration.update();
        }, 1000 * 3600);
      },
      cached: () => {
        nonProdConsoleInfo(`${this.logPrefix} Content has been cached for offline use.`);
      },
      updatefound: (registration: ServiceWorkerRegistration) => {
        // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
        this.swLogger('New content is downloading.', {
          installing: registration.installing,
          waiting: registration.waiting,
          active: registration.active,
        });
      },
      updated: (registration: ServiceWorkerRegistration) => {
        this.swLogger('New content is available;', {
          installing: registration.installing,
          waiting: registration.waiting,
          active: registration.active,
        });

        this.onServiceWorkerUpdate(registration);
      },
      offline: () => {
        this.swLogger('No internet connection found. App is running in offline mode.');
      },
      error: (error) => {
        this.swLogger('Error during service worker registration:', error);
      },
    });
  };

  private readonly tryActivateWaiting = (registrations: ReadonlyArray<ServiceWorkerRegistration>) => {
    const workersPendingActivation = registrations.filter((registration) => registration.waiting);

    const shouldReload = this.sendSkipWaiting(workersPendingActivation.pop());

    if (shouldReload) {
      this.reloadCurrentPage('Reloading after skip waiting registration');
    }

    return shouldReload;
  };

  private removeOldCaches = () => {
    nonProdConsoleInfo(`${this.logPrefix} removing old caches`);
    const isOldAppCache = (cacheName: string) => cacheName.startsWith('bge-precache');

    caches.keys().then((cacheNames) =>
      Promise.all(
        cacheNames.map((key: string) => {
          nonProdConsoleInfo('processing cache:', key);

          if (isOldAppCache(key)) {
            return caches.delete(key).then(() => nonProdConsoleInfo(`Cleared ${key}`));
          }

          return Promise.resolve();
        }),
      ),
    );
  };

  public init = ({ shouldInit }: { shouldInit: boolean }) => {
    if (window.frameElement /** do not register in an iframe */ || !shouldInit || !window.navigator?.serviceWorker) {
      return;
    }

    nonProdConsoleInfo(`${this.logPrefix} init`);

    this.removeOldCaches();

    // eslint-disable-next-line compat/compat
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        nonProdConsoleInfo(`${this.logPrefix} current registrations`, registrations);
        const shouldReload = this.tryActivateWaiting(registrations);

        if (!shouldReload) {
          window.addEventListener('load', () => {
            this.doRegistration();
          });
        }
      })
      .catch((e) => {
        this.swLogger('Registrations manipulation failed', e);

        /**
         * getRegistrations will always fail on FF with "Delete cookies and site data when Firefox is closed" enabled
         * Error: DOMException: The operation is insecure.
         */
        if (e.name !== 'SecurityError') {
          this.reloadCurrentPage('Reloading after registrations manipulation failure');
        }
      });
  };

  public refreshApp = () => {
    this.appUpdateRequested.value = true;
    this.swLogger('App update requested', {
      installing: this.registration.value?.installing,
      waiting: this.registration.value?.waiting,
      active: this.registration.value?.active,
    });
    const registrationWaiting = this.registration.value?.waiting;

    if (registrationWaiting != null) {
      this.serviceWorkerUpdated.value = false;
      this.subscribeOnStatechange(registrationWaiting);
      this.sendSkipWaiting(this.registration.value);
    } else {
      this.reloadCurrentPage('No waiting registration, reloading the page...');
    }
  };

  public startListeningForAppUpdate = () => {
    window.addEventListener('app-update-requested', this.refreshApp.bind(this), true);
  };

  public stopListeningForAppUpdate = () => {
    window.removeEventListener('app-update-requested', this.refreshApp.bind(this), true);
  };
}

export default new ServiceWorkerUpdateChecker();
