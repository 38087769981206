import { AxiosInstance } from 'axios';

import type { RateLimitWarnings } from '@exchange/libs/settings/service/src';
import { rateLimitTracker } from '@exchange/libs/utils/rate-limit-tracker/src';

const rateLimitInterceptor = (axios: AxiosInstance, getRateLimitWarningsSettings: () => RateLimitWarnings) => {
  axios.interceptors.response.use(
    (response) => {
      rateLimitTracker.processResponseHeaders(response.headers, getRateLimitWarningsSettings());

      return response;
    },
    (error) => {
      rateLimitTracker.processResponseHeaders(error?.response?.headers || {}, getRateLimitWarningsSettings());

      return Promise.reject(error);
    },
  );
};

export default rateLimitInterceptor;
