import { Assets, BitmapFont, settings } from 'pixi.js-legacy';
import '@pixi/unsafe-eval';

const pixi = {
  initialized: {} as Dictionary<boolean>,
  initializePromise: {} as Dictionary<Promise<string>>,

  browserAdapter: {
    createCanvas: (width, height) => {
      const canvas = document.createElement('canvas');

      canvas.width = width;
      canvas.height = height;
      return canvas;
    },
    getCanvasRenderingContext2D: () => CanvasRenderingContext2D,
    getWebGLRenderingContext: () => WebGLRenderingContext,
    getNavigator: () => navigator,
    getBaseUrl: () => window.location.origin,
    getFontFaceSet: () => document.fonts,
    fetch: (url, options) => fetch(url, options),
    parseXML: (xml) => {
      const parser = new DOMParser();

      return parser.parseFromString(xml, 'text/xml');
    },
  },
  setSettings: () => {
    /** https://api.pixijs.io/@pixi/settings/PIXI/settings.html */
    settings.ADAPTER = pixi.browserAdapter;
  },
  initialize: async (fntFileName: string) => {
    if (pixi.initialized.fntFileName) {
      return Promise.resolve(pixi.initialized.fntFileName);
    }

    try {
      pixi.setSettings();
      const loadPromise = Assets.load(fntFileName);

      pixi.initializePromise[fntFileName] = loadPromise.then((value: BitmapFont) => value.font);

      const resolvedTexture = await loadPromise;

      BitmapFont.from(resolvedTexture);
      pixi.initialized.fntFileName = true;

      return pixi.initialized.fntFileName;
    } catch (error) {
      throw new Error(`PIXI init failed ${(error as { message?: string })?.message}`);
    }
  },
  initializeAwait: (fntFileName: string) => {
    if (pixi.initializePromise[fntFileName]) {
      pixi.initialize(fntFileName);
    }

    return pixi.initializePromise[fntFileName];
  },
};

export default pixi;
