import { throttleWithOptions } from '@exchange/helpers/lodash-fp-no-cap';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';

class RateLimitTracker {
  private readonly quotaHeader = 'bp-remaining-quota';

  private readonly lowLimit = 50;

  private showCloseToHitting = throttleWithOptions(
    60_000,
    () => {
      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'warning',
          title: 'fundamentals.warning',
          message: 'modules.rateLimitTracker.toasts.warning.message',
        },
      });
    },
    { leading: true, trailing: false },
  );

  private showLimitHit = throttleWithOptions(
    60_000,
    () => {
      toastManagerInstance.addToast({
        content: SimpleToast,
        props: {
          variant: 'failed',
          title: 'modules.rateLimitTracker.toasts.hit.title',
          message: 'modules.rateLimitTracker.toasts.hit.message',
        },
      });
    },
    { leading: true, trailing: false },
  );

  public processResponseHeaders = (headers: Dictionary<unknown>, { showCloseToHitting = true, showHit = true }) => {
    if (showCloseToHitting === false && showHit === false) {
      return undefined;
    }

    const quota = Number(headers[this.quotaHeader]);

    if (this.lowLimit >= quota) {
      if (quota !== 0) {
        return showCloseToHitting && this.showCloseToHitting();
      }

      return showHit && this.showLimitHit();
    }

    return undefined;
  };
}

export default new RateLimitTracker();
