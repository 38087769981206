import { launchdarkly, PromoConfigFlag, PromoFlag } from '@exchange/libs/utils/launchdarkly/src';

import usePromoTiming from './usePromoTiming';

const usePromoMarkets = (ldFlag: string | undefined = PromoFlag.market) => {
  const getPromoConfig: () => Promise<PromoConfigFlag> = () => launchdarkly.getWhenReady(ldFlag as PromoFlag.market) as Promise<PromoConfigFlag>;

  const { promoIsActive } = usePromoTiming(getPromoConfig);

  return {
    promoIsActive,
  };
};

export default usePromoMarkets;
