import colorcolor from 'colorcolor';
import { ref } from 'vue';

import persistanceHelper from '@exchange/helpers/persistance-helper';

import { themes } from './themes';

class ThemingService {
  private colorCache: Map<string, string> | undefined = undefined;

  private fontSizeCache: Map<string, number> | undefined = undefined;

  private fontWeightCache: Map<string, string> | undefined = undefined;

  public resetCache = () => {
    this.colorCache = undefined;
    this.fontSizeCache = undefined;
    this.fontWeightCache = undefined;
  };

  /** name of CSS custom properties (--*) -- */
  public static getProperty = (element?: Element | null) => (name: string) => (element ? getComputedStyle(element).getPropertyValue(name).trim() : undefined);

  /** name of CSS custom properties (--*) -- */
  public static getAppProperty = (name: string) => {
    const element = document.querySelector('#app-holder');

    return ThemingService.getProperty(element)(name);
  };

  /** name of CSS custom properties (--*) -- */
  public getColor = (name: string, checkCache = true) => {
    if (!this.colorCache) {
      this.colorCache = new Map<string, string>();
    }

    const defaultColor = '#000000';

    if (checkCache && this.colorCache && this.colorCache.has(name)) {
      const c = this.colorCache.get(name);

      return c === undefined ? defaultColor : c;
    }

    const c = ThemingService.getAppProperty(name);

    if (!c) {
      return defaultColor;
    }

    let colorValue: string | undefined;

    if (c.startsWith('#')) {
      colorValue = colorcolor(c, 'hex') as string;
    } else {
      colorValue = colorcolor(`rgb(${c})`, 'hex') as string;
    }

    this.colorCache?.set(name, colorValue);

    return colorValue;
  };

  public static getBaseFontSize = () => Number(process.env.VUE_APP_BASE_FONT_SIZE ?? 16);

  public static getBaseFontWeight = () => '400';

  public getFontSize = (size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', checkCache = true) => {
    if (!this.fontSizeCache) {
      this.fontSizeCache = new Map<string, number>();
    }

    const baseFontSize = ThemingService.getBaseFontSize();

    if (checkCache && this.fontSizeCache && this.fontSizeCache.has(size)) {
      const cs = this.fontSizeCache.get(size);

      return cs === undefined ? baseFontSize : cs;
    }

    const fontSizeValue = Number(ThemingService.getAppProperty(`--font-size-${size}`)?.replace('rem', '') || 0) * baseFontSize || baseFontSize;

    this.fontSizeCache.set(size, fontSizeValue);

    return fontSizeValue;
  };

  public getFontWeight = (weight: 'light' | 'regular' | 'medium' | 'bold', checkCache = true) => {
    if (!this.fontWeightCache) {
      this.fontWeightCache = new Map<string, string>();
    }

    const baseFontWeight = ThemingService.getBaseFontWeight();

    if (checkCache && this.fontWeightCache && this.fontWeightCache.has(weight)) {
      const cw = this.fontWeightCache.get(weight);

      return cw === undefined ? baseFontWeight : cw;
    }

    const fontWeightValue = ThemingService.getAppProperty(`font-weight-${weight}`) || baseFontWeight;

    this.fontWeightCache.set(weight, fontWeightValue);

    return fontWeightValue;
  };

  public static hexToRgba = (hex: string, alpha = 1) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    // eslint-disable-next-line no-param-reassign
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      throw new Error(`cannot convert color ${hex}`);
    }

    const [on, tw, th] = result;

    if (!on || !tw || !th) {
      throw new Error(`cannot convert color ${hex}`);
    }

    return `rgba(${parseInt(on, 16)}, ${parseInt(tw, 16)}, ${parseInt(th, 16)}, ${alpha})`;
  };

  public static hexToRgbNumbers = (hex: string): [number, number, number] => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    // eslint-disable-next-line no-param-reassign
    hex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      throw new Error(`cannot convert color ${hex}`);
    }

    const [on, tw, th] = result;

    if (!on || !tw || !th) {
      throw new Error(`cannot convert color ${hex}`);
    }

    return [parseInt(on, 16), parseInt(tw, 16), parseInt(th, 16)];
  };

  public appTheme = {
    key: 'data-theme',

    themes,

    defaultTheme: themes.default,

    currentThemeName: ref(themes.default.name),

    getCurrentThemeConfig: () => themes[this.appTheme.currentThemeName.value] || themes.default,

    setTheme: (theme: string) => {
      let themeToApply = theme;

      if (Object.keys(themes).indexOf(theme) === -1) {
        themeToApply = themes.default.name;
      }

      document.documentElement.setAttribute(this.appTheme.key, themeToApply);
      this.appTheme.currentThemeName.value = themeToApply;
    },

    persist: (theme: string) => {
      persistanceHelper.localstorageSet(this.appTheme.key, theme);
    },

    apply: () => {
      const theme = persistanceHelper.getObjFromJSON(this.appTheme.key, themes.default.name);

      this.appTheme.setTheme(theme);
      this.resetCache();
    },
  };
}

const ths = new ThemingService();

export default ths;
